import type { FC, PropsWithChildren } from 'react';

import { useLogout } from '@/hooks/useLogout';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';

type Props = {
  asChild?: boolean;
};

const UserDropdown: FC<PropsWithChildren<Props>> = ({ children, asChild }) => {
  const { mutate } = useLogout();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        asChild={asChild}
        className="flex items-center gap-3"
      >
        {children}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem onClick={() => mutate()}>Logout</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserDropdown;
