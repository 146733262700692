import { faChevronDown, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { FC } from 'react';

import { useProfile } from '@/hooks/useProfile';

import Text from '../Text';
import UserDropdown from './UserDropdown';

type Props = {
  dark?: boolean;
};

const HeaderUser: FC<Props> = ({ dark }) => {
  const { data } = useProfile();

  return (
    <UserDropdown>
      <FontAwesomeIcon
        icon={faUser}
        className={classNames('aspect-square rounded-full', {
          'text-black': dark,
          'text-white': !dark,
        })}
      />
      <Text
        className={classNames('hidden md:block', {
          'text-black': dark,
          'text-white': !dark,
        })}
        variant="body-small"
      >
        {data?.name}
      </Text>

      <FontAwesomeIcon
        className={classNames({ 'text-black': dark })}
        icon={faChevronDown}
      />
    </UserDropdown>
  );
};

export default HeaderUser;
