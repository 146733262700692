import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';

import Button from '../Button';

const HeaderLogin = () => {
  return (
    <>
      <Link href="/dashboard/auth/signin">
        <Button className="hidden md:block" variant="secondary">
          Log in
        </Button>
        <Button
          className="block p-3 md:hidden"
          variant="unstyled"
          size={null}
          prefixIcon={faRightToBracket}
        />
      </Link>
      <Link className="hidden md:block" href="/dashboard/auth/signup">
        <Button>Sign up</Button>
      </Link>
    </>
  );
};

export default HeaderLogin;
