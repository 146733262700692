import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { type FC, useState } from 'react';

import Logo from '@/assets/logo.svg';
import LogoCVBlack from '@/assets/logo-cv-black.svg';
import LogoCVWhite from '@/assets/logo-cv-white.svg';
import { isDevelopment } from '@/config/config';
import { useProfile } from '@/hooks/useProfile';

import BackButton from '../BackButton';
import Button from '../Button';
import CvUsage from './CvUsage';
import Hamburger from './Hamburger';
import HeaderLogin from './Login';
import HeaderUser from './User';

type Props = {
  disablePadding?: boolean;
  dark?: boolean;
  showBack?: boolean;
  onPressBack?: () => void;
};

export type LinkType = { name: string; href?: string; links?: LinkType[] };

const Header: FC<Props> = ({
  disablePadding = false,
  dark = false,
  showBack = true,
  onPressBack,
}) => {
  const router = useRouter();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const FullLogo = dark ? LogoCVBlack : LogoCVWhite;
  const { data } = useProfile();

  const showButton = router.pathname !== '/' && showBack;

  return (
    <>
      <div
        className={classNames(
          'grid grid-cols-3 grid-flow-col h-28 md:grid-cols-[repeat(2,_min-content)_minmax(0,_3fr)] z-10 shrink-0',
          {
            'py-5 px-5 md:px-20': !disablePadding,
          }
        )}
      >
        <div className="flex items-center justify-start">
          {showButton && (
            <BackButton
              className={classNames('px-8 py-4', {
                'text-black': dark,
              })}
              onClick={onPressBack}
            />
          )}
        </div>
        <div className="z-10 flex items-center justify-center gap-5 md:-order-1">
          <Link href={data || isDevelopment ? '/dashboard' : '/'}>
            <div className="hidden md:block">
              <FullLogo />
            </div>
            <div
              className={classNames(
                'flex h-7 w-14 justify-center text-white md:hidden',
                {
                  '!text-black': dark,
                }
              )}
            >
              <Logo />
            </div>
          </Link>
        </div>
        <div className="flex justify-end">
          <div className="flex items-center gap-3">
            <CvUsage className="hidden md:block" dark={dark} />
            <div className="flex items-center gap-3">
              {data ? <HeaderUser dark={dark} /> : <HeaderLogin />}
            </div>
            {router.pathname !== '/' && (
              <Button variant="unstyled" size={null}>
                <FontAwesomeIcon
                  icon={faBars}
                  onClick={() => setSidebarOpen(true)}
                  className={classNames('p-3', {
                    'text-black': dark,
                    'text-white': !dark,
                  })}
                />
              </Button>
            )}
          </div>
          <Hamburger
            isOpen={sidebarOpen}
            onClose={() => setSidebarOpen(false)}
          />
        </div>
      </div>
      <CvUsage className="block px-7 pb-3 md:hidden" dark={dark} />
    </>
  );
};

export default Header;
